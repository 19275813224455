import { last } from 'lodash'
import loadImage from 'blueimp-load-image/js/load-image'
import 'blueimp-load-image/js/load-image-orientation'
import 'blueimp-load-image/js/load-image-exif'
import { randomString } from '~/utils/string'

const decodeBase64 = image => {
  const byteString  = atob(image.split(',')[1])
  const mimeString = image.split(',')[0].split(':')[1].split(';')[0]
  const integerArray = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    integerArray[i] = byteString.charCodeAt(i)
  }
  return {integerArray, mimeString}
}

const base64toBlob = (image) => {
  const base64Data = decodeBase64(image)
  return new Blob([base64Data.integerArray], {type: base64Data.mimeString})
}

const getFilename = file => {
  const extension = /\.[a-zA-Z]/.test(file.name) ? last(file.name.split('.')) : 'png'
  return `${ randomString(16) }.${ extension }`
}

export const resize = async (file, options = {}) => {
  const image = await new Promise((resolve, reject) => {
    loadImage(
      file,
      (img, data) => img.type === 'error' ? reject(img.type) : resolve(img),
      { ...options, orientation: true, canvas: true }
    )
  })

  return new File([base64toBlob(image.toDataURL(file.type))], getFilename(file))
}