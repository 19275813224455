import React, { Component, createElement } from 'react'
import cx from 'classnames'
import { noop } from 'lodash'
import { withContext } from './context'
import style from './field.module.scss'

class Field extends Component {

  static defaultProps = {
    onChange: noop,
    validate: noop,
  }

  state = {
    errorMessage: '',
    isTouched: false,
    isVisited: false,
  }

  componentDidMount() {
    const { name, registerField } = this.props
    this.unregisterField = registerField(name, this)
    this.handleChange()
  }

  componentDidUpdate(prevProps) {
    // @todo, this is a terrible idea
    if (prevProps.validate(this.value) !== this.props.validate(this.value)) {
      this.handleChange()
    }
  }

  componentWillUnmount() {
    this.unregisterField()
  }

  get value() {
    return this.input ?
      this.props.type === 'checkbox' ? Boolean(this.input.checked) : this.input.value :
      undefined
  }

  reset() {
    this.input.reset()
  }

  focus() {
    this.input.focus()
  }

  reportValidity() {
    return !this.props.validate(this.value)
  }

  checkValidity() {
    this.setState({ isTouched: true })
    return !this.props.validate(this.value)
  }

  handleChange = () => {
    const { value } = this
    const { onFieldChange, name, onChange, validate } = this.props
    const errorMessage = validate(value)
    this.setState({ isTouched: true, errorMessage }, () => {
      onChange(value)
      onFieldChange(name)
    })
  }

  handleBlur = () => {
    this.setState({ isVisited: true })
  }

  render() {
    const {
      name, label, component = 'input', validate, selectionControlLabel = '',
      className, registerField, onFieldChange, shouldAlwaysDisplayError, ...otherProps } = this.props
    const { errorMessage, isTouched, isVisited } = this.state
    const Component = component

    /* @todo, remove the need for need for type="checkbox" when using Checkbox */
    const isSelectionControl = otherProps.type === 'checkbox' || otherProps.type === 'radio'
    const props = {
      ...otherProps,
      ...(!otherProps.readOnly && !otherProps.disabled ? {
        onInput: this.handleChange,
        onBlur: this.handleBlur,
      } : {}),
      ...(isSelectionControl ? {
        onChange: this.handleChange,
        defaultValue: undefined,
        defaultChecked: otherProps.defaultValue,
        label: selectionControlLabel,
      } : {}),
    }

    const shouldDisplayError = errorMessage && ((isTouched && isVisited) || shouldAlwaysDisplayError)

    return (
      <div className={ cx(style.field, className, {
        [style.hasError]: shouldDisplayError,
      }) }>
      {
        label ?
          <label className={ style.label }>{ label }</label> :
          null
      }
      {
        <Component
          name={ name }
          ref={ node => this.input = node }
          { ...props }
        />
      }
      {
        shouldDisplayError ?
          <p role="alert">{ errorMessage }</p> :
          null
      }
      </div>
    )
  }
}

export default withContext(Field)
