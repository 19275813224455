import React, { Component } from 'react'
import { forEach, map, mapValues, noop, omit, every } from 'lodash'
import { Provider } from './context'

export default class Form extends Component {

  static defaultProps = {
    onSubmit: noop,
    onChange: noop,
  }

  state = {
    isSubmitting: false,
    isValid: false,
  }

  fields = {}

  registerField = (name, instance) => {
    if (!name) {
      throw new Error('Field name must be provided')
    }

    this.fields[name] = instance
    return this.unregisterField.bind(this, name)
  }

  unregisterField = (name) => {
    this.fields = omit(this.fields, [name])
  }

  handleFieldChange = () => {
    const { onChange } = this.props
    this.setState({
      isValid: every(this.fields, field =>
        field.reportValidity()
      ),
    }, () => onChange(this.getValues(), this.state.isValid))
  }

  handleSubmit = (ev) => {
    ev.preventDefault()
    const { onSubmit } = this.props
    this.setState({ isSubmitting: true }, () =>
      Promise.resolve(
        every(this.fields, field => field.checkValidity()) ?
          onSubmit(this.getValues()) :
          noop()
      ).then(() => this.setState({ isSubmitting: false }))
    )
  }

  handleReset = () => forEach(this.fields, (field) => field.reset())

  getValues = () => mapValues(this.fields, 'value')

  render() {
    const { render, children, onChange, ...other } = this.props
    const { isSubmitting, isValid } = this.state
    const values = this.getValues()

    return (
      <Provider value={{
        registerField: this.registerField,
        onFieldChange: this.handleFieldChange,
      }}>
        <form
          {...other}
          onReset={ this.handleReset }
          onSubmit={ this.handleSubmit }
          noValidate
          autoComplete="off"
        >
          { render ? render({ isSubmitting, isValid, values }) : children }
        </form>
      </Provider>
    )
  }
}

export { default as Field } from './Field'
