import React, { forwardRef, Component } from 'react'
import cx from 'classnames'
import style from './selectioncontrol.module.scss'

const SelectionControl = ({label, className, disabled, type, innerRef, ...props}) => (
  <div className={ cx(
    style.main,
    style[`${ type }Type`], {
      [style.disabledMain]: disabled,
    }, className) }>
    <label className={ style.label }>
      <input
        {...props}
        className={ style.input }
        type={ type }
        ref={ innerRef }
      />
      <span className={ cx(style.control, {
        [style.checkedControl]: props.checked || props.defaultChecked,
      }) } />
      { label }
    </label>
  </div>
)

export default SelectionControl

export const Radio = forwardRef((props, ref) =>
  <SelectionControl type="radio" {...props} innerRef={ ref } />
)

export const Checkbox = forwardRef((props, ref) =>
  <SelectionControl type="checkbox" {...props} innerRef={ ref } />
)
