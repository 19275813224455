import React from 'react'
import { get, map } from 'lodash'
import routes from '~/routes'
import Markdown from 'components/common/Markdown'
import NavigationHelper from 'components/block/NavigationHelper'
import { CategoryBreadcrumb } from 'components/block/Breadcrumb'
import Feedback from 'components/block/Feedback'
import SearchBar from 'components/block/SearchBar'
import ContentItems from 'components/block/ContentItem/ContentItems'
import style from './category.module.scss'

export default ({ data }) => {
  const { title, article: articles } = data

  return (
    <div className={ style.main }>
      <NavigationHelper title={ title }>
        <CategoryBreadcrumb category={ data } />
      </NavigationHelper>
      <div className={ style.container }>
        <SearchBar />
      </div>
      <ContentItems
        className={ style.items }
        items={ map(articles, article => ({
          title: article.title,
          url: routes.article(article),
          image: get(article, 'heroImage.fluid.src'),
        }))
      } />
    </div>
  )
}