import React, { Fragment } from 'react'
import { StaticQuery, navigate, graphql, Link } from 'gatsby'
import { t } from '@lingui/macro'
import routes from '~/routes'
import { map, truncate } from 'lodash'
import { Dropdown } from 'components/common/Input'
import { compact, putFirst, selectNodes } from '~/utils/misc'
import { filterLocalizedNodes } from '~/utils/content'
import { addBasePath, mapHeadingIds } from '~/utils/html'
import style from './breadcrumb.module.scss'

const Breadcrumb = ({ parts }) => {
  const compacted = compact(parts)
  return (
    <div className={ style.main }>
    {
      map(compacted, (options, index) => (
        <Fragment key={ index }>
          {
            options.length > 1 ?
              <Dropdown
                renderSelection={ label =>
                  <span className={ style.selection }>
                    { truncate(label, { length: 30 }) }
                  </span>
                }
                className={ style.dropdown }
                options={ options }
                onChange={ navigate }
              /> :
              <Link className={ style.selection } to={ options[0][0] }>
                { options[0][1] }
              </Link>
          }
          {
            index < compacted.length - 1 ?
              <span className={ style.separator } /> :
              null
          }
        </Fragment>
      ))
    }
    </div>
  )
}

export default Breadcrumb

const withCategoriesQuery = Wrapped =>
  props => (
    <StaticQuery
      query={graphql`
        query CategoriesQuery {
          allContentfulCategory(filter: { listable: { eq: true }})  {
            edges {
              node {
                node_locale
                title
                slug
                visibilityGroup {
                  node_locale
                  title
                  slug
                }

                article {
                  node_locale
                  title
                  slug

                  category {
                    visibilityGroup {
                      node_locale
                      title
                      slug
                    }
                  }
                }
              }
            }
          }
        }`
      }
      render={ ({ allContentfulCategory }) =>
        <Wrapped
          {...props}
          categories={ filterLocalizedNodes(selectNodes(allContentfulCategory.edges), props.locale) }
        />
      }
    />
  )

const sortBySlug = (items, { slug }) =>
  putFirst(items, item => item.slug === slug)

const makeLinksWith = (items, fn) =>
  items ?
    map(items, item => ([ fn(item), item.title ])) :
    undefined

export const VisibilityGroupBreadcrumb = ({ visibilityGroup }) => (
  <Breadcrumb
    parts={[
      makeLinksWith([visibilityGroup], routes.visibilityGroup),
    ]}
  />
)

export const CategoryBreadcrumb = withCategoriesQuery(({ categories, category }) => (
  <Breadcrumb
    parts={[
      makeLinksWith([category.visibilityGroup], routes.visibilityGroup),
      makeLinksWith(sortBySlug(categories, category), routes.category)
    ]}
  />
))

const makeInnerArticleLinks = (article, { pathname }) =>
  article && article.body ?
    addBasePath(mapHeadingIds(article.body.childMarkdownRemark.html), pathname) :
    undefined

export const ArticleBreadcrumb = withCategoriesQuery(({ categories, article, location }) => (
  <Breadcrumb
    parts={[
      makeLinksWith([article.category.visibilityGroup], routes.visibilityGroup),
      makeLinksWith(sortBySlug(categories, article.category), routes.category),
      makeLinksWith(sortBySlug(article.category.article, article), routes.article),
      makeInnerArticleLinks(article, location),
    ]}
  />
))

export const InstitutionalBreadcrumb = ({ article, location }) => (
  <Breadcrumb
    parts={[
      [[routes.institutional(article), article.title]],
      makeInnerArticleLinks(article, location),
    ]}
  />
)