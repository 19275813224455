import React from 'react'
import { get } from 'lodash'
import { Default as Layout } from 'components/layout/Layout'
import Category from 'components/section/Category'

export default ({ pageContext = {}, location }) => (
  <Layout location={ location }>
    <Category data={ get(pageContext, 'data.category') } />
  </Layout>
)
