import React from 'react'
import cx from 'classnames'
import { map } from 'lodash'
import ContentItem from './index'
import style from './contentitems.module.scss'

export default ({ className, items }) => (
  <div className={ cx(style.main, className) }>
  {
    map(items, ({ title, image, url }, index) =>
      <ContentItem
        className={ style.item }
        key={ index }
        title={ title }
        image={ image }
        url={ url }
      />
    )
  }
  </div>
)
