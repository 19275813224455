import React from 'react'
import cx from 'classnames'
import { isUndefined, noop } from 'lodash'
import { withLocale } from 'components/common/Locale'
import { Trans } from '@lingui/macro'
import ThumbIcon from '~/assets/images/icon/thumb.svg'
import style from './feedback.module.scss'

const Option = ({ isDisabled, isSelected, onClick, children, className }) => (
  <div className={ cx(style.option, className, {
    [style.disabledOption]: isDisabled,
    [style.selectedOption]: isSelected,
  }) } onClick={ !isDisabled ? onClick : noop }>
    <div className={ style.icon }>
      <ThumbIcon />
    </div>
    { children }
  </div>
)

export default withLocale(({ isDisabled, feedback, i18n, className, onPositive = noop, onNegative = noop }) => (
  <div className={ cx(style.main, className) }>
    <h4 className={ style.title }>
      <Trans>¿Te ha resultado útil?</Trans>
    </h4>
    <div className={ style.options }>
      <Option
        className={ style.positiveOption}
        onClick={ onPositive }
        isSelected={ feedback === true }
        isDisabled={ isDisabled || !isUndefined(feedback) }
      >
        <Trans>Sí</Trans>
      </Option>
      <Option
        className={ style.negativeOption}
        onClick={ onNegative }
        isSelected={ feedback === false }
        isDisabled={ isDisabled || !isUndefined(feedback) }
      >
        <Trans>No</Trans>
      </Option>
    </div>
  </div>
))