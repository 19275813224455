import React, { Component } from 'react'
import { noop } from 'lodash'
import { Trans } from '@lingui/macro'
import cx from 'classnames'
import Button from 'components/common/Button'
import Loading from 'components/common/Loading'
import { upload } from '~/service/storage'
import { resize } from '~/utils/image'
import CameraIcon from '~/assets/images/icon/camera.svg'
import style from './imageupload.module.scss'

export default class ImageUpload extends Component {
  static defaultProps = {
    onChange: noop,
  }

  static getDerivedStateFromProps({ defaultValue }, { currentValue, lastDefaultValue }) {
    return (
      currentValue === undefined && defaultValue !== lastDefaultValue ?
      {
        currentValue: defaultValue,
        lastDefaultValue: defaultValue,
      } : {}
    )
  }

  constructor(props) {
    const { defaultValue } = props
    super(props)
    this.state = {
      currentValue: defaultValue,
    }
  }

  get value() {
    return this.state.currentValue
  }

  set value(newValue) {
    const { onInput = noop } = this.props
    this.setState({ currentValue: newValue }, () => onInput(newValue))
  }

  reset() {
    const { defaultValue } = this.props
    this.value = defaultValue
  }

  async handleInputChange (ev) {
    this.setState({
      hasError: false,
      isUploading: true,
    })

    try {
      const { uploadMaxFilesize = Infinity } = this.props
      const fileInput = ev.target
      const file = await resize(fileInput.files[0], { maxWidth: 1024, maxHeight: 1024 })

      if (file) {
        const isFileTooLarge = file.size >= uploadMaxFilesize
        this.setState({ isFileTooLarge })
        if (!isFileTooLarge) {
          const result = await upload(file)
          this.value = result.url
        }
        fileInput.value = ''
      }
    } catch (e) {
      console.log(e)
      this.setState({ hasError: true })
    }

    this.setState({ isUploading: false })
  }

  render() {
    const { i18n, uploadLabel, uploadButton, className = '', disabled, onBlur = noop, ...other } = this.props
    const { currentValue, isUploading, hasError, isFileTooLarge } = this.state

    return (
      <div className={ cx(style.main, className) }>
        <div className={ style.avatar }>
          {
            !currentValue ?
              <div className={ style.empty }>
                <CameraIcon />
              </div> :
              <div className={ style.image } style={{
                backgroundImage: `url(${ currentValue })`,
              }} />
          }
          {
            isUploading ?
              <Loading /> :
              null
          }
        </div>
        <div className={ style.file }>
          <label className={ style.label }>{ uploadLabel }</label>
          <input
            type="file"
            accept="image/*"
            disabled={ disabled }
            className={ style.input }
            onChange={ ev => this.handleInputChange(ev) }
            onBlur={ ev => ev.target.files.length === 0 ? onBlur() : noop() }
          />
          <Button isDisabled={ disabled } isSecondaryDark>
            { uploadButton }
          </Button>
          {
            hasError || isFileTooLarge ?
            <p role="alert" className={ style.error }>
              {
                isFileTooLarge ?
                  <Trans>El archivo es demasiado grande</Trans> :
                  <Trans>Ha ocurrido un error subiendo la imagen</Trans>
              }
            </p> :
            null
          }
        </div>
      </div>
    )
  }
}


