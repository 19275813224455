import React, { Component } from 'react'
import cx from 'classnames'
import { compact, map, noop, includes, isEmpty, last } from 'lodash'
import { heads } from '~/utils/misc'
import Input from './Input'
import style from './typeaheadgroup.module.scss'

export default class TypeaheadGroup extends Component {
  static defaultProps = {
    onInput: noop,
    onChange: noop,
    defaultValue: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      currentItems: props.defaultValue,
      removedItems: [],
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue && isEmpty(this.state.currentItems)) {
      this.setState({ currentItems: nextProps.defaultValue })
    }
  }

  get value() {
    const { removedItems, currentItems } = this.state
    return compact(currentItems.filter((item, index) => !removedItems.includes(index)))
  }

  set value(nextItems) {
    this.setState({ currentItems: nextItems }, () => this.props.onInput(this.value))
  }

  reset = () => {
    const { defaultValue } = this.props
    this.value = nextItems
  }

  handleUpdateItem = (index, value) => {
    const { currentItems: nextItems } = this.state
    const { options } = this.props
    if (includes(heads(options), value) || this.props.isOpen) {
      nextItems[index] = value
    }
    this.value = nextItems
  }

  handleRemoveItem = index => {
    this.setState(({ removedItems }) => ({
      removedItems: [...removedItems, index],
    }), () => this.props.onInput(this.value))
  }

  handleAddEmptyItem = () => {
    const { currentItems } = this.state
    const nextItems = [...currentItems, '']
    this.value = nextItems
  }

  render() {
    const { addLabel = 'Add', options, className = '', name, disabled, isOpen, onBlur } = this.props
    const { currentItems, removedItems } = this.state
    const items = isEmpty(currentItems) ? [''] : currentItems
    const nonRemovedItems = items.filter((i, n) => !removedItems.includes(n))

    return (
      <div className={ cx(style.main, className) }>
        {
          map(items, (value, index) =>
            <Input
              key={ index }
              onInput={ disabled ? noop : ev => this.handleUpdateItem(index, ev) }
              onBlur={ onBlur }
              isRemoved={ removedItems.includes(index) }
              canRemove={ nonRemovedItems.length > 1 }
              onRemove={ () => this.handleRemoveItem(index) }
              disabled={ disabled }
              defaultValue={ value }
              options={ options }
              isOpen={ isOpen }
            />
          )
        }
        {
          !disabled && !isEmpty(last(nonRemovedItems)) ?
            <span
              onClick={ this.handleAddEmptyItem }
              className={ style.add }
            >{ addLabel }</span> :
            null
        }
      </div>
    )
  }
}
