import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import ArrowIcon from '~/assets/images/icon/arrow.svg'
import style from './contentitem.module.scss'

export default ({ url, title, image, className }) => (
  <Link to={ url } className={ cx(style.main, className) }>
    <div className={ style.image } style={ image ? {
      backgroundImage: `url(${ image })`,
    } : undefined } />
    <span className={ style.title }>
      { title }
      <span className={ style.icon }>
        <ArrowIcon />
      </span>
    </span>
  </Link>
)
