import React from 'react'
import { navigate } from 'gatsby'
import { noop } from 'lodash'
import { Trans } from '@lingui/macro'
import Button from 'components/common/Button'
import Form, { Field } from 'components/common/Form'
import routes from '~/routes'
import style from './searchbar.module.scss'

export default ({ defaultQuery }) => (
  <div className={ style.main }>
    <Form
      onSubmit={ ({ query }) => navigate(routes.search(query)) }
      render={ ({ isSubmitting, isValid }) =>
        <div className={ style.inputGroup }>
          <Field
            name="query"
            defaultValue={ defaultQuery }
            className={ style.input }
            autoComplete="off"
          />
          <Button isBlue component="button" className={ style.button }>
            <Trans>Buscar</Trans>
          </Button>
        </div>
      }
    />
  </div>
)
