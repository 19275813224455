import React, { Component } from 'react'
import { includes, map, noop } from 'lodash'
import { heads } from '~/utils/misc'
import { Radio } from '../SelectionControl'
import style from './radiogroup.module.scss'

export default class RadioGroup extends Component {
  static defaultProps = {
    onInput: noop,
    options: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      currentValue: props.defaultValue,
    }
  }

  get value() {
    return this.state.currentValue
  }

  set value(newValue) {
    const { options, onInput } = this.props
    if (includes(heads(options), newValue)) {
      this.setState({ currentValue: newValue }, () => onInput(newValue))
    }
  }

  reset() {
    const { options, defaultValue } = this.props
    this.value = defaultValue
  }

  handleChange(newValue) {
    this.value = newValue
  }

  render() {
    const { options, className = '', name, disabled, onBlur, ...other } = this.props
    const { currentValue } = this.state

    return (
      <div className={ style.main } onBlur={ onBlur }>
      {
        map(options, ([value, label]) =>
          <Radio
            key={ value }
            label={ label }
            className={ style.radio }
            onClick={ disabled ? noop : () => this.handleChange(value) }
            name={ name }
            value={ value }
            checked={ currentValue === value }
            disabled={ disabled }
            readOnly
          />
        )
      }
      </div>
    )
  }
}
