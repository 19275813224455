import React, { Component } from 'react'
import cx from 'classnames'
import { tailByHead, heads, tails } from '~/utils/misc'
import Chevron from '~/assets/images/icon/chevron.svg'
import { first, includes, identity, map, noop, uniqueId, sortBy } from 'lodash'
import style from './dropdown.module.scss'

export default class Dropdown extends Component {
  static defaultProps = {
    renderSelection: identity,
    onInput: noop,
    onChange: noop,
    options: [],
    id: '',
  }

  id = undefined
  el = undefined

  constructor(props) {
    super(props)
    this.state = {
      currentValue: props.defaultValue || first(heads(props.options)),
    }
    this.id = props.id || uniqueId('dropdown')
  }

  get value() {
    return this.state.currentValue
  }

  set value(newValue) {
    const { options, onInput, onChange } = this.props
    if (includes(heads(options), newValue)) {
      this.setState({ currentValue: newValue }, () => {
        onInput(newValue)
        onChange(newValue)
      })
      if (this.el) {
        this.el.blur()
      }
    }
  }

  reset() {
    const { options, defaultValue } = this.props
    this.value = defaultValue || first(heads(options))
  }

  focus() {
    this.el.focus()
  }

  handleChange(newValue) {
    this.value = newValue
  }

  render() {
    const { renderSelection, options, disabled, className = '' } = this.props
    const { currentValue } = this.state
    const sortedOptions = sortBy(options, ([value]) => value === currentValue ? -1 : 1)
    const longest = first(sortBy(tails(options), label => label.length)) || ''
    const hasOptions = options.length > 1

    return (
      <div
        id={ this.id }
        ref={ node => this.el = node }
        className={ cx(style.main, className) }
        tabIndex={ hasOptions && !disabled ? 0 : undefined }
      >
        <div role="button" aria-haspopup="listbox" className={ cx(style.selection, {
          [style.hasOptionsSelection]: hasOptions,
        }) }>
          { renderSelection(tailByHead(options, currentValue), options) }
          {
            hasOptions ?
              <span className={ style.chevron }>
                <Chevron />
              </span> :
              null
          }
        </div>
        <ul
          role="listbox"
          className={ cx(style.options, {
            [style.wideOptions]: longest.length > 50,
          }) }
        >
          {
            map(sortedOptions, ([value, label]) =>
              <li
                key={ value }
                id={ value }
                role="option"
                aria-selected={ currentValue === value }
                onClick={ !disabled ? () => this.handleChange(value) : undefined }
                className={ style.option }
              >
                { label }
              </li>
            )
          }
        </ul>
      </div>
    )
  }
}
