import React, { PureComponent } from 'react'
import cx from 'classnames'
import { includes, isEqual, map, memoize, noop, take } from 'lodash'
import { heads, tailByHead } from '~/utils/misc'
import Fuse from 'fuse.js'
import style from './typeahead.module.scss'

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['value', 'label'],
}

const makeFuseOptions = memoize((options = []) =>
  new Fuse(options.reduce((accum, [ value, label ]) =>
    ([...accum, { value, label }]),
  []), fuseOptions)
)

export default class Typeahead extends PureComponent {
  static defaultProps = {
    defaultValue: '',
    minSearchLength: 1,
    onInput: noop,
    options: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      isExpanded: false,
      currentSearch: this.getPresentableValue(props.defaultValue),
      currentValue: props.defaultValue,
    }
  }

  UNSAFE_componentWillMount() {
    this.fuse = this.makeFuse(this.props.options)
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!isEqual(nextProps.options, this.props.options)) {
      this.fuse = this.makeFuse(this.props.options)
    }
  }

  makeFuse = memoize(makeFuseOptions)

  get value() {
    return this.state.currentValue
  }

  set value(newValue) {
    const { options, onInput } = this.props
    this.setState({
      currentValue: newValue,
      currentSearch: newValue,
    }, () => onInput(newValue))

    this.searchInput.value = this.getPresentableValue(newValue)
  }

  getPresentableValue = value =>
    this.props.isOpen ? value : (tailByHead(this.props.options, value) || '')

  reset() {
    const { options, defaultValue } = this.props
    this.value = defaultValue
  }

  handleChange = newValue => {
    this.value = newValue
    this.setExpanded(false)
    this.props.onBlur()
  }

  handleSearchChange = ev => {
    if (!this.props.isOpen) {
      this.setState({
        currentSearch: ev.target.value,
      })
    } else {
      this.value = ev.target.value
    }
  }

  setExpanded = isExpanded => this.setState({ isExpanded })

  render() {
    const {
      options, className = '', minSearchLength, disabled, defaultValue, onBlur, ...other } = this.props
    const { currentValue, currentSearch, isExpanded } = this.state
    const filtered = this.fuse.search(currentSearch)

    return (
      <div
        className={ cx(style.main, className) }
        role="combobox"
        aria-expanded={ isExpanded }
        aria-autocomplete="list"
        tabIndex={ disabled ? undefined : 0 }
        {...(!disabled ? {
            onFocus: () => this.setExpanded(true),
            onBlur: () => this.setExpanded(false),
          } : {})
        }
      >
        <input
          className={ style.input }
          ref={ node => this.searchInput = node }
          onInput={ this.handleSearchChange }
          onBlur={ onBlur }
          defaultValue={ this.getPresentableValue(defaultValue) }
          disabled={ disabled }
          type="text"
          autoComplete="off"
        />
        {
          isExpanded ?
            <div
              className={ style.options }
              aria-hidden={ ! currentSearch.length > minSearchLength }
              role="listbox"
            >
            {
              map(take(filtered, 8), ({value, label}, index) =>
                <div
                  key={ value }
                  role="option"
                  className={ style.option }
                  aria-selected={ currentValue === value }
                  onMouseDown={ disabled ? noop : () => this.handleChange(value) }
                >
                  { label }
                </div>
              )
            }
            </div> :
            null
        }
      </div>
    )
  }
}
