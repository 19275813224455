import React, { Component, createRef, forwardRef } from 'react'
import cx from 'classnames'
import { compose, debounce } from 'lodash'
import { withViewportScrollAwareness } from 'components/common/ViewportScrollAware'
import style from './navigationhelper.module.scss'

let contentNode = createRef()

const NavigationHelper = forwardRef(({ isScrollingUp, isAboveViewport, title, children }, ref) => {
  const height = contentNode.current ? contentNode.current.getBoundingClientRect().height : 0

  return (
    <div
      ref={ ref }
      style={{
        minHeight: isAboveViewport ? height : 0,
      }}
      className={ cx(style.main, {
        [style.mainAboveFold]: isAboveViewport,
      }) }
    >
      <div
        ref={ contentNode }
        className={ cx(style.content, {
          [style.aboveViewportContent]: isAboveViewport,
          [style.scrollingUpContent]: isScrollingUp,
        }) }
      >
        <div className={ cx(style.container, {
          [style.aboveViewportContainer]: isAboveViewport,
          [style.scrollingUpContainer]: isScrollingUp,
        }) }>
          <h1 className={ style.title }>{ title }</h1>
          { children }
        </div>
      </div>
    </div>
  )
})

export default withViewportScrollAwareness(NavigationHelper)