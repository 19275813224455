import React, { Component } from 'react'
import cx from 'classnames'
import { fromPairs, map, noop, uniq, without } from 'lodash'
import { heads } from '~/utils/misc'
import { Checkbox } from '../SelectionControl'
import style from './checkboxgroup.module.scss'

export default class CheckboxGroup extends Component {
  static defaultProps = {
    onChange: noop,
    options: [],
  }

  constructor(props) {
    const { defaultValue = [], options } = props
    super(props)
    this.state = {
      currentValue: defaultValue,
    }
  }

  get value() {
    return this.state.currentValue
  }

  set value(newValue) {
    const { onChange } = this.props
    this.setState({ currentValue: newValue }, () => onChange(newValue))
  }

  reset() {
    const { options, defaultValue } = this.props
    this.value = defaultValue
  }

  handleChange(key, nextIsChecked) {
    this.value = nextIsChecked ? [...this.value, key] : without(this.value, key)
  }

  render() {
    const { options, className = '', name, disabled, onBlur, ...other } = this.props
    const { currentValue } = this.state

    return (
      <div className={ cx(style.main, className) } onBlur={ onBlur }>
      {
        map(options, ([key, label]) =>
          <Checkbox
            key={ key }
            label={ label }
            className={ style.checkbox }
            onClick={ disabled ? noop : ev => this.handleChange(key, !currentValue.includes(key)) }
            name={ name }
            value={ key }
            checked={ currentValue.includes(key) }
            disabled={ disabled }
            readOnly
          />
        )
      }
      </div>
    )
  }
}
