import React, { forwardRef } from 'react'
import cx from 'classnames'
import Typeahead from '../Typeahead'
import Select from '../Select'
import Trash from '~/assets/images/icon/trash.svg'
import style from './typeaheadgroup.module.scss'

export default forwardRef(({ canRemove, isRemoved, onRemove,  ...props }, ref) => (
  <div className={ cx(style.line, {
    [style.isRemoved]: isRemoved,
  }) }>
    <Typeahead
      ref={ ref }
      className={ style.input }
      {...props}
    />
    {
      !props.disabled && canRemove && onRemove ?
        <span
          className={ style.icon }
          onClick={ onRemove }
        >
          <Trash />
        </span> :
        null
    }
  </div>
))