import React, { createElement } from 'react'
import { isFunction } from 'lodash'
import { fromMarkdown } from '~/utils/html'

export default ({ component = 'div', shouldAddHeadingIds = false, content, ...props }) =>
  createElement(component, {
    ...props,
    dangerouslySetInnerHTML: {
      __html: fromMarkdown(content || '', { shouldAddHeadingIds })
    },
  })
