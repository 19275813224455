import React, { forwardRef } from 'react'
import cx from 'classnames'
import { map } from 'lodash'
import ChevronIcon from '~/assets/images/icon/chevron.svg'
import style from './select.module.scss'

export default forwardRef(({ options, className, ...props }, ref) => {
  return (
    <div className={ cx(style.main, className) }>
      <select
        key={ props.defaultValue } /* @hack */
        className={ style.select }
        {...props}
        ref={ ref }
      >
      {
        map(options, ([value, label]) =>
          <option key={ value } value={ value }>{ label }</option>
        )
      }
      </select>
      <span className={ style.chevron }>
        <ChevronIcon />
      </span>
    </div>
  )
})
