import settings from '~/settings'
import { config, S3 } from 'aws-sdk'
import { randomString } from '~/utils/string'

config.accessKeyId = settings.s3.accessKey
config.secretAccessKey = settings.s3.secretAccessKey
config.region = settings.s3.bucketRegion

export async function upload(file) {
  const params = { Key: file.name, Body: file }
  const bucket = new S3({ params: { Bucket: settings.s3.bucketName }})
  return new Promise((resolve, reject) =>
    bucket.upload(params, (err, data) =>
      err ?
        reject(err) :
        resolve({
          url: data.Location
        })
    )
  )
}
