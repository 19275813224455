import React, { Component } from 'react'
import cx from 'classnames'
import { includes, map, noop } from 'lodash'
import { heads } from '~/utils/misc'
import style from './pills.module.scss'

export default class Pills extends Component {
  static defaultProps = {
    onInput: noop,
    options: [],
  }

  static getDerivedStateFromProps({ defaultValue }, { currentValue, lastDefaultValue }) {
    return (
      currentValue === undefined && defaultValue !== lastDefaultValue ?
      {
        currentValue: defaultValue,
        lastDefaultValue: defaultValue,
      } : {}
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      currentValue: props.defaultValue,
    }
  }

  get value() {
    return this.state.currentValue
  }

  set value(newValue) {
    const { options, onInput } = this.props
    if (includes(heads(options), newValue)) {
      this.setState({ currentValue: newValue }, () => onInput(newValue))
    }
  }

  reset() {
    const { options, defaultValue } = this.props
    this.value = defaultValue
  }

  handleChange(newValue) {
    this.value = newValue
  }

  render() {
    const { options, className = '', disabled, ...other } = this.props
    const { currentValue } = this.state

    return (
      <div
        role="listbox"
        className={ cx(style.main, {
          [style.disabledMain]: disabled,
        }, className) }
        tabIndex={ disabled ? undefined : 0 }
        { ...other }
      >
      {
        map(options, ([value, label]) =>
          <div
            key={ value }
            role="option"
            className={ style.option }
            aria-selected={ currentValue === value }
            onClick={ disabled ? noop : () => this.handleChange(value) }
          >
            { label }
          </div>
        )
      }
      </div>
    )
  }
}
